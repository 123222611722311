import { MobileProps } from "../domain"

export const ContactUs: React.FunctionComponent<MobileProps> = (isMobile) => {
    return <>
        <section id="contactUs" className={`${isMobile ? 'py-0 pb-4' : 'py-5'}`}>
            <div className="container">
                <div className="row">
                    <div className="col-12 py-3">
                        <div className="bg-holder bg-size" style={
                            {
                                backgroundImage: 'url(assets/img/gallery/contact-us.png)',
                                backgroundPosition: 'top center',
                                backgroundSize: 'contain'
                            }
                        }>
                        </div>
                        <h1 className="text-center">CONTACT US</h1>
                    </div>
                </div>
            </div>
        </section>
        <section className={`${isMobile ? 'py-5' : 'py-8'}`}>
            <div className="bg-holder bg-size" style={
                {
                    backgroundImage: 'url(assets/img/gallery/people-bg-1.png)',
                    backgroundPosition: 'center',
                    backgroundSize: 'cover'
                }
            }>
            </div>

            <div >
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6 text-center text-md-start">
                            <h2 className="fw-bold mb-4 lh-base">
                                Aggarwal Dental Care
                            </h2>
                            <br className="d-none d-sm-block" />
                            <p className="fs-1">
                                <strong>7 Dhuni Chand Road,
                                    <br />
                                    Jammun Wali Sadak,
                                    <br/> Lawrence Road,
                                    <br />
                                    Amritsar
                                    <br />
                                </strong>
                            </p>
                            <p className={`text-start ${isMobile ? '' : 'fs-1'}`}>
                                <br />
                                <strong>                                    
                                    <svg width="30" height="30" viewBox="0 0 512 512">
                                        <path fill="#283779" d="M415.9,335.5c-14.6-15-56.1-43.1-83.3-43.1c-6.3,0-11.8,1.4-16.3,4.3c-13.3,8.5-23.9,15.1-29,15.1c-2.8,0-5.8-2.5-12.4-8.2  l-1.1-1c-18.3-15.9-22.2-20-29.3-27.4l-1.8-1.9c-1.3-1.3-2.4-2.5-3.5-3.6c-6.2-6.4-10.7-11-26.6-29l-0.7-0.8  c-7.6-8.6-12.6-14.2-12.9-18.3c-0.3-4,3.2-10.5,12.1-22.6c10.8-14.6,11.2-32.6,1.3-53.5c-7.9-16.5-20.8-32.3-32.2-46.2l-1-1.2  c-9.8-12-21.2-18-33.9-18c-14.1,0-25.8,7.6-32,11.6c-0.5,0.3-1,0.7-1.5,1c-13.9,8.8-24,20.9-27.8,33.2c-5.7,18.5-9.5,42.5,17.8,92.4  c23.6,43.2,45,72.2,79,107.1c32,32.8,46.2,43.4,78,66.4c35.4,25.6,69.4,40.3,93.2,40.3c22.1,0,39.5,0,64.3-29.9  C442.3,370.8,431.5,351.6,415.9,335.5z"/>
                                    </svg> +91-9878626654
                                    <br />
                                    <svg className="me-1" width="30" height="30" viewBox="0 0 512 512">
                                        <g>
                                            <path fill="#283779" d="M67,148.7c11,5.8,163.8,89.1,169.5,92.1c5.7,3,11.5,4.4,20.5,4.4c9,0,14.8-1.4,20.5-4.4c5.7-3,158.5-86.3,169.5-92.1   c4.1-2.1,11-5.9,12.5-10.2c2.6-7.6-0.2-10.5-11.3-10.5H257H65.8c-11.1,0-13.9,3-11.3,10.5C56,142.9,62.9,146.6,67,148.7z"></path>
                                            <path fill="#283779" d="M455.7,153.2c-8.2,4.2-81.8,56.6-130.5,88.1l82.2,92.5c2,2,2.9,4.4,1.8,5.6c-1.2,1.1-3.8,0.5-5.9-1.4l-98.6-83.2   c-14.9,9.6-25.4,16.2-27.2,17.2c-7.7,3.9-13.1,4.4-20.5,4.4c-7.4,0-12.8-0.5-20.5-4.4c-1.9-1-12.3-7.6-27.2-17.2l-98.6,83.2   c-2,2-4.7,2.6-5.9,1.4c-1.2-1.1-0.3-3.6,1.7-5.6l82.1-92.5c-48.7-31.5-123.1-83.9-131.3-88.1c-8.8-4.5-9.3,0.8-9.3,4.9   c0,4.1,0,205,0,205c0,9.3,13.7,20.9,23.5,20.9H257h185.5c9.8,0,21.5-11.7,21.5-20.9c0,0,0-201,0-205   C464,153.9,464.6,148.7,455.7,153.2z"></path>
                                        </g>
                                    </svg> aggarwaldentalcare.asr@gmail.com
                                </strong>
                            </p>
                        </div>
                        <div className="col-md-6 mb-5 mb-lg-0">
                            <a href={`${isMobile ? "https://goo.gl/maps/e437pR8VVKLAuE3M6" : "https://www.google.com/maps/place/Dr.+Richa+Aggarwal+C%2Fo+AGGARWAL+DENTAL+CARE/@31.6488374,74.8779317,17z/data=!3m1!4b1!4m6!3m5!1s0x391963c09e841465:0x808c831f26f985ec!8m2!3d31.6488374!4d74.8797263!16s%2Fg%2F11fl9gzmqx"}`}>
                                <img
                                    className="w-100"
                                    src="assets/img/gallery/map.png"
                                    alt="..."
                                />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
}