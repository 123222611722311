import { useState } from "react";
import { AboutUs } from "../compounds/AboutUs";
import { Appointment } from "../compounds/Appointment";
import { ContactUs } from "../compounds/ContactUs";
import { Footer } from "../compounds/Footer";
import { HeroVideo } from "../compounds/HeroVideo";
import { Home } from "../compounds/Home";
import { Navbar } from "../compounds/Navbar";
import { Reviews } from "../compounds/Reviews";
import { Services } from "../compounds/Services";
import { Technology } from "../compounds/Technology";

const Root = () => {
  const isMobile = document.querySelector('html')?.classList.contains('mobile') || false;
  const [showVideo, setVideoVisibility] = useState(false);
  return (
    <>
      <main className="main" id="top">
        {showVideo && <HeroVideo
          isMobile={isMobile}
          toggleVideoVisibility={(videoVisibility: boolean)=>setVideoVisibility(videoVisibility)}
          showVideo={showVideo}
        />}
        <Navbar isMobile={isMobile}/>
        <Home
          isMobile={isMobile}
          toggleVideoVisibility={(videoVisibility: boolean)=>setVideoVisibility(videoVisibility)}
          showVideo={showVideo}
        />
        <Services isMobile={isMobile}/>
        <Technology isMobile={isMobile}/>
        <AboutUs />
        <ContactUs isMobile={isMobile}/>
        <Appointment isMobile={isMobile}/>
        <Reviews />
        <Footer />
      </main>
    </>
  );
};

export default Root;
