import { Star } from "../common/Star"

export const Reviews = () => {
    // const fetchReviews = async () => {
    //     console.log('Fetching reviews from google');
    //     const response = await axios.get('https://www.google.com/maps/preview/place?authuser=0&hl=en&gl=in&pb=!1m22!1s0x391963c09e841465%3A0x808c831f26f985ec!2s!3m12!1m3!1d3269651.6185270203!2d70.2654684875!3d31.648837399999987!2m3!1f0!2f0!3f0!3m2!1i998!2i722!4f13.1!4m2!3d31.648705289976853!4d74.88006591796874!5e4!15m2!1m1!4s%2Fg%2F11fl9gzmqx!6sdr+richa+agarwal!13m50!2m2!1i408!2i240!3m2!2i10!5b1!7m42!1m3!1e1!2b0!3e3!1m3!1e2!2b1!3e2!1m3!1e2!2b0!3e3!1m3!1e8!2b0!3e3!1m3!1e10!2b0!3e3!1m3!1e10!2b1!3e2!1m3!1e9!2b1!3e2!1m3!1e10!2b0!3e3!1m3!1e10!2b1!3e2!1m3!1e10!2b0!3e4!2b1!4b1!9b0!14m4!1sGANlZOnEMNGAmgaQz4vYAw!3b1!7e81!15i10555!15m50!1m10!4e2!18m7!3b0!6b0!14b1!17b1!20b1!27m1!1b0!20e2!2b1!4b1!5m6!2b1!3b1!5b1!6b1!7b1!10b1!10m1!8e3!11m1!3e1!17b1!20m2!1e3!1e6!24b1!25b1!26b1!29b1!30m1!2b1!36b1!43b1!52b1!55b1!56m2!1b1!3b1!65m5!3m4!1m3!1m2!1i224!2i298!107m2!1m1!1e1!22m1!1e81!29m0!30m3!3b1!6m1!2b1!32b1!37i646&q=*&pf=t');
    //     console.log(`Fetched response from google review api: ${JSON.stringify(response)}`);
    //     return response;
    // }

    // useEffect(()=> {
    //     (async() => {
    //         await fetchReviews();
    //     })();
    // }, []);

    return <>
        <section className="py-5">
            <div className="container">
                <div className="row">
                    <div className="col-12 py-3">
                        <div className="bg-holder bg-size" style={
                            {
                                backgroundImage: 'url(assets/img/gallery/people.png)',
                                backgroundPosition: 'top center',
                                backgroundSize: 'contain'
                            }
                        }>
                        </div>
                        <h1 className="text-center">PEOPLE WHO LOVE US</h1>
                    </div>
                </div>
            </div>
        </section>
        <section className="py-8">
            <div className="bg-holder bg-size" style={
                {
                    backgroundImage: 'url(assets/img/gallery/people-bg-1.png)',
                    backgroundPosition: 'center',
                    backgroundSize: 'cover'
                }
            }>
            </div>
            <div className="container">
                <div className="row align-items-center offset-sm-1">
                    <div className="carousel slide" id="carouselPeople" data-bs-ride="carousel">
                        <div className="carousel-inner">
                            <div className="carousel-item active" data-bs-interval="10000">
                                <div className="row h-100">
                                    <div className="col-sm-3 text-center"><img src="assets/img/gallery/people-who-love.png" width="100" alt="" />
                                        <h5 className="mt-3 fw-medium text-secondary">Suman Rawat</h5>
                                    </div>
                                    <div className="col-sm-9 text-center text-sm-start pt-3 pt-sm-0">
                                        <div className="my-2">
                                            <Star />
                                            <Star />
                                            <Star />
                                            <Star />
                                            <Star />
                                        </div>
                                        <p>It is the best dental clinic in Amritsar without a doubt. I visited here a few times recently. She is polite, intelligent and answered all the questions.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="carousel-item" data-bs-interval="2000">
                                <div className="row h-100">
                                    <div className="col-sm-3 text-center"><img src="assets/img/gallery/people-who-love.png" width="100" alt="" />
                                        <h5 className="mt-3 fw-medium text-secondary">Rachna</h5>
                                    </div>
                                    <div className="col-sm-9 text-center text-sm-start pt-3 pt-sm-0">
                                        <div className="my-2">
                                            <Star />
                                            <Star />
                                            <Star />
                                            <Star />
                                            <Star />
                                        </div>
                                        <p>Dr Richa and her team are extremely professional and her dental work is the best I’ve experienced. Having travelled from the UK to Amritsar, I was apprehensive at the start, but from the moment I met with her to discuss my dental plan she put my mind at ease. The results speak for themselves and I would travel back to Amritsar in a heartbeat for any future work. She’s AMAZING.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="carousel-item">
                                <div className="row h-100">
                                    <div className="col-sm-3 text-center"><img src="assets/img/gallery/people-who-love.png" width="100" alt="" />
                                        <h5 className="mt-3 fw-medium text-secondary">Seema Sethi</h5>
                                    </div>
                                    <div className="col-sm-9 text-center text-sm-start pt-3 pt-sm-0">
                                        <div className="my-2">
                                            <Star />
                                            <Star />
                                            <Star />
                                            <Star />
                                            <Star />
                                        </div>
                                        <p>Dr Richa is very professional and experienced in her field. I found her very polite and patient. She makes her patients very comfortable. I am very happy and satisfied with her work. Staff is also very polite.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="position-relative z-index-2 mt-5">
                                <ol className="carousel-indicators">
                                    <li className="active" data-bs-target="#carouselPeople" data-bs-slide-to="0"></li>
                                    <li data-bs-target="#carouselPeople" data-bs-slide-to="1"></li>
                                    <li data-bs-target="#carouselPeople" data-bs-slide-to="2"> </li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
}