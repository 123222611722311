import { useState } from "react";
import { MobileProps } from "../domain";

export const Technology: React.FunctionComponent<MobileProps> = (isMobile) => {
  const [learnMoreVisible, setLearnMoreVisible] = useState(false);
  return (
    <>
      <section id="technology" className="bg-secondary">
        <div className="bg-holder" style={
          {
            backgroundImage: 'url(assets/img/gallery/bg-eye-care.png)',
            backgroundPosition: 'center',
            backgroundSize: 'contain'
          }
        }>
        </div>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-5 col-xxl-6"><img className="img-fluid" src="assets/img/gallery/cbct.png" alt="..." /></div>
            <div className="col-md-7 col-xxl-6 text-center text-md-start">
              <h2 className="fw-bold text-light mb-4 mt-4 mt-lg-0">
                Dental Care with Best Technology
                <br className="d-none d-sm-block" />
                and Top Professionals
              </h2>
              <p className="text-light">We are the first dental clinic in the region to install state of 
                <br className="d-none d-sm-block" />
                {" "}the art <strong>CBCT</strong> and <strong>OPG</strong> machine to provide better diagnosis 
                <br className="d-none d-sm-block" />
                {" "}and treatment planning to our patients. 
                {learnMoreVisible && <>
                  <br className="d-none d-sm-block" />
                  <br />
                  <strong>CBCT</strong> scan gives a three dimensional image of dental structures, nerve 
                  {" "}<br className="d-none d-sm-block" />
                  pathways and bone in a single scan. While an OPG is a two 
                  {" "}<br className="d-none d-sm-block" />
                  dimensional wide view X-ray of the lower face which 
                  {" "}<br className="d-none d-sm-block" />
                  displays all the teeth of upper and lower jaw in a 
                  {" "}<br className="d-none d-sm-block" />
                  single film. 
                  {" "}<br className="d-none d-sm-block" />
                  <br />
                  <strong>OPG</strong> and <strong>CBCT</strong> play a key role in modern dentistry as these are highly 
                  <br className="d-none d-sm-block" />
                  {" "}advanced imaging techniques which help clinicians in making  in depth 
                  <br className="d-none d-sm-block" />
                  {" "}analysis of oral cavity and jaw bones and then make treatment 
                  <br className="d-none d-sm-block" />
                  {" "}plan accordingly.
                  <br className="d-none d-sm-block" />
                  <div className={`position-absolute ${isMobile ? 'mt-4' : 'mx-6'}`} onClick={() => setLearnMoreVisible(!learnMoreVisible)} style={
                    {
                      right: isMobile ? '2rem' : '4rem'
                    }
                  }>
                    <svg enable-background="new 0 0 0 0" height="32px" viewBox="0 0 32 32" width="32px" role="button">
                      <path d="M18.221,7.206l9.585,9.585c0.879,0.879,0.879,2.317,0,3.195l-0.8,0.801c-0.877,0.878-2.316,0.878-3.194,0  l-7.315-7.315l-7.315,7.315c-0.878,0.878-2.317,0.878-3.194,0l-0.8-0.801c-0.879-0.878-0.879-2.316,0-3.195l9.587-9.585  c0.471-0.472,1.103-0.682,1.723-0.647C17.115,6.524,17.748,6.734,18.221,7.206z" fill="#ffffff"></path>
                    </svg>
                  </div>
                </>}
              </p>
              {!learnMoreVisible && <div className="py-3">
                <button className="btn btn-lg btn-light rounded-pill" onClick={() => setLearnMoreVisible(!learnMoreVisible)}>Learn more </button>
              </div>}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
