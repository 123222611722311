import { DentalService } from "../domain";

export const DentalServicesData: DentalService[] = [
    {
        id: 1,
        name: "Braces Invisalign",
        icon: "braces.png",
        iconHover: "braces-blue.png",
        afterImage: "braces-service.png",
        imageTitle: "Steel Braces and Invisalign Braces",
        description: `Braces helps to correct the teeth which are
        <br className="d-none d-sm-block" />
        crooked, crowding and out of alignment. Braces
        <br className="d-none d-sm-block" />
        straighten the teeth slowly and it helps to 
        <br className="d-none d-sm-block" />
        adjust the smile.
        <br className="d-none d-sm-block" />
        Invisalign are invisible braces which donot
        <br className="d-none d-sm-block" />
        require any metal parts. They are very 
        <br className="d-none d-sm-block" />
        comfortable in straightening method. It covers
        <br className="d-none d-sm-block" />
        the teeth and pulls them gently into their proper position.`
    },
    {
        id: 2,
        name: "Root Canal",
        icon: "rct.png",
        iconHover: "rct-blue.png",
        afterImage: "rct-service.png",
        imageTitle: "Root Canal Treatment",
        description: `Root canal treatment is designed to eliminate bacteria
        from the infected root canal, prevent reinfection of the
        tooth and save the natural tooth.
        <br className="d-none d-sm-block" />
        When one undergoes a root canal treatment, 
        <br className="d-none d-sm-block" />
        the inflamed or infected pulp is removed
        <br className="d-none d-sm-block" />
        and the inside of the tooth is carefully cleaned
        <br className="d-none d-sm-block" />
        and disinfected, then filled and sealed.`
    },
    {
        id: 3,
        name: "Tooth Whitening",
        icon: "white.png",
        iconHover: "white-blue.png",
        afterImage: "whitening-service.png",
        imageTitle: "Tooth Whitening Service",
        description: `Tooth whitening is a bleaching process which 
        <br className="d-none d-sm-block" />
        lightens the colour of human teeth. When
        <br className="d-none d-sm-block" />
        teeth become yellow over a period of 
        <br className="d-none d-sm-block" />
        time it can be changed with bleaching 
        <br className="d-none d-sm-block" />
        thus changing the colour of tooth enamel.
        <br className="d-none d-sm-block" />
        As teeth can be discoloured, professional 
        <br className="d-none d-sm-block" />
        teeth bleaching is the usual method
        <br className="d-none d-sm-block" />
        for the whitening process.`
    },
    {
        id: 4,
        name: "Dental Implants",
        icon: "implant.png",
        iconHover: "implant-blue.png",
        afterImage: "implant-service.png",
        imageTitle: "Dental Implants",
        description: `Dental Implants are small screws made
        <br className="d-none d-sm-block" />
        up of titanium, which are surgically implanted to
        <br className="d-none d-sm-block" />
        to the jaw. The abutment of the screw holds the
        <br className="d-none d-sm-block" />
        artificial teeth firmly giving a natural tooth
        <br className="d-none d-sm-block" />
        like appearence, strength and durability.`
    },
    {
        id: 5,
        name: "Tooth Jewellery",
        icon: "jewel.png",
        iconHover: "jewel-blue.png",
        afterImage: "jewellery-service.png",
        imageTitle: "Tooth Jewellery",
        description: `Tooth jewellery is to attach jewellery such as
        <br className="d-none d-sm-block" />
        rhinestones or jewels on the teeth. It is attached 
        <br className="d-none d-sm-block" />
        to the surface of the teeth. For some, an 
        <br className="d-none d-sm-block" />
        entire tooth is applied or a jewel cap
        <br className="d-none d-sm-block" />
        is placed whereas in others one stone on the
        <br className="d-none d-sm-block" />
        center of tooth is attached, depending
        <br className="d-none d-sm-block" />
        on the requirements.`
    },
    {
        id: 6,
        name: "Crown & Bridge Work",
        icon: "crown.png",
        iconHover: "crown-blue.png",
        afterImage: "crown-service.png",
        imageTitle: "Dental Crown and Bridge Work",
        description: `Crown is a restoration that covers the surface
        <br className="d-none d-sm-block" />
        which is exposed of a tooth to strengthen it.
        <br className="d-none d-sm-block" />
        Bridge is a restoration process to replace 
        <br className="d-none d-sm-block" />
        one or more missing teeth.
        <br className="d-none d-sm-block" />
        If you have deteriorating tooth and you want 
        <br className="d-none d-sm-block" />
        to enhance or improve the look of your tooth, 
        <br className="d-none d-sm-block" />
        the best option would be a crown. If you have 
        <br className="d-none d-sm-block" />
        multiple missing teeth, the bridge would
        <br className="d-none d-sm-block" />
        be a better choice.`
    },
];
