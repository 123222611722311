export const AboutUs = () => {
  return (
    <>
      <section className="pb-0" id="about">
        <div className="container">
          <div className="row">
            <div className="col-12 py-3">
              <div
                className="bg-holder bg-size"
                style={{
                  backgroundImage: "url(assets/img/gallery/about-us.png)",
                  backgroundPosition: "top center",
                  backgroundSize: "contain",
                }}
              ></div>
              <h1 className="text-center">ABOUT US</h1>
            </div>
          </div>
        </div>
      </section>
      <section className="py-5">
        <div
          className="bg-holder bg-size"
          style={{
            backgroundImage: "url(assets/img/gallery/about-bg.png)",
            backgroundPosition: "top center",
            backgroundSize: "contain",
          }}
        ></div>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6 mb-5 mb-lg-0">
              <img
                className="fit-cover rounded-circle w-100"
                src="assets/img/gallery/dr_richa.png"
                alt="..."
              />
            </div>
            <div className="col-md-6 text-center text-md-end">
              <h2 className="fw-bold mb-4">
                Meet Your Smile Specialist
                <br className="d-none d-sm-block" />
              </h2>
              <p>
                <strong>Dr. Richa Aggarwal</strong> received her Bachelors and Master’s
                <br className="d-none d-sm-block" />
                {" "}degree in Dental Surgery from Government Dental 
                <br className="d-none d-sm-block" />
                {" "}College, Amritsar. She has done her Master's in 
                <br className="d-none d-sm-block" />
                {" "}Orthodontics and Dentofacial orthopaedics {'('}braces
                <br className="d-none d-sm-block" />
                {" "}specialist{')'}. She has more than 10 years of clinical
                <br className="d-none d-sm-block" />
                {" "}experience in diagnosis and treatment planning,
                <br className="d-none d-sm-block" />
                {" "} preventative care, prosthodontic dentistry.
                <br className="d-none d-sm-block" />
                {" "}Dr. Aggarwal believes that the responsibility of a  
                <br className="d-none d-sm-block" />
                {" "}dentist is to educate and guide patients so they can 
                <br className="d-none d-sm-block" />
                {" "}make their own decisions on oral health care.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
