export const Home: React.FunctionComponent<any> = ({ isMobile, toggleVideoVisibility, showVideo }) => {
  return (
    <section className="py-xxl-10 pb-0" id="home">
      <div
        className="bg-holder bg-size"
        style={{
          backgroundImage: "url(assets/img/gallery/hero-bg.png)",
          backgroundPosition: "top center",
          backgroundSize: "cover",
        }}
      ></div>

      <div className="container">
        <div className="row min-vh-xl-100 min-vh-xxl-25">
          <div className="col-md-6 col-xl-6 col-xxl-7 order-0 order-md-1 text-end icon-box">
            <img
              className={`${isMobile ? 'pt-4' : ''} pt-md-4 w-100 deparment-icon`}
              src="assets/img/gallery/hero_home.png"
              alt="hero-header"
            />
            <img
              className={`${isMobile ? 'pt-4' : ''} pt-md-4 w-100 deparment-icon-hover pointer`}
              src="assets/img/gallery/hero_home_hover.png"
              alt="hero-header-hover"
              onClick={() => toggleVideoVisibility(!showVideo)}
            />
          </div>
          <div className={`col-md-6 col-xl-6 col-xxl-5 text-md-start text-center ${isMobile ? 'py-3' : 'py-6'}`}>
            <h1 className="fw-light font-base fs-6 fs-xxl-7">
              We're <strong>determined </strong>for
              <br />
              your&nbsp;<strong>better smile.</strong>
            </h1>
            <p className="fs-1 mb-5">
              You get the best dental care you need in <br />
              the city with  CBCT and OPG technology <br />
              available in house.<br />
              You will be treated by caring specialist doctors.{" "}
            </p>
            <a
              className="btn btn-lg btn-primary rounded-pill"
              href="#appointments"
              role="button"
            >
              Make an Appointment
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};
