export enum AppointmentForm {
    NAME = 'entry.1038050191',
    PHONE = 'entry.996424238',
    EMAIL = 'entry.1943809822',
    CATEGORY = 'entry.1931377805',
    MESSAGE = 'entry.1663197351',
    CREATED_TIMESTAMP = 'entry.1149855464',
}

export interface AppointmentFormData {
    [AppointmentForm.NAME]: string;
    [AppointmentForm.PHONE]: string;
    [AppointmentForm.EMAIL]: string;
    [AppointmentForm.CATEGORY]: string;
    [AppointmentForm.MESSAGE]: string;
    [AppointmentForm.CREATED_TIMESTAMP]: string;
}
