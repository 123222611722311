export const HeroVideo: React.FunctionComponent<any> = ({ isMobile, toggleVideoVisibility, showVideo }) => {
  const getVideoSizeMultiplier = () => window.innerWidth < 480 ? .90 : .80;
  return (
    <>
      <iframe
        style={{
          position: "fixed",
          zIndex: "2100",
          borderRadius: "20px",
          top: (1 - getVideoSizeMultiplier()) * window.innerHeight / 2,
          left: (1 - getVideoSizeMultiplier()) * window.innerWidth / 2
        }}
        width={window.innerWidth * getVideoSizeMultiplier()}
        height={window.innerHeight * getVideoSizeMultiplier()}
        src="https://www.youtube.com/embed/3ixuWnTG08w?si=P1Occ9rkzqOnHy6X"
        title="YouTube video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerPolicy="strict-origin-when-cross-origin"
        allowFullScreen
      ></iframe>
      <div
        className="bg-holder overlay overlay-0 z-index--top"
        onClick={() => toggleVideoVisibility(!showVideo)}
      ></div>
    </>
  );
};
