import React, { useState } from "react";
import { DentalServicesData } from "../data";
import { MobileProps } from "../domain";

export const Services: React.FunctionComponent<MobileProps> = ({isMobile}) => {
  const [selectedService, selectService] = useState(DentalServicesData[0]);
  return (
    <>
      <section className={`${isMobile ? 'py-4' : 'py-5'}`} id="services">
        <div className="container">
          <div className="row">
            <div className="col-12 py-3">
              <div
                className="bg-holder bg-size"
                style={{
                  backgroundImage: "url(assets/img/gallery/our-services.png)",
                  backgroundPosition: "top center",
                  backgroundSize: "contain",
                }}
              ></div>
              <h1 className="text-center">OUR SERVICES</h1>
            </div>
          </div>
        </div>
      </section>
      <section className="py-0">
        <div className="container mt-2">
          <div className={`row ${!isMobile ? 'py-5 align-items-center justify-content-center justify-content-lg-evenly' : ''}`}>
            {DentalServicesData.map((service, index) => {
              return (
                <div className={`${isMobile ? "col-6" : "col-auto col-md-4 col-lg-auto text-xl-start"}`} key={`${index}-service-${service.name}`}>
                  <div className="d-flex flex-column align-items-center">
                    <div className="icon-box text-center">
                      <a className="text-decoration-none" role="button" onClick={() => selectService(service)}>
                        <img
                          className={`${isMobile ? 'mb-1' : 'mb-3'} deparment-icon`}
                          src={`assets/img/icons/${selectedService.name === service.name ? service.iconHover : service.icon}`}
                          alt="..."
                        />
                        <img
                          className={`${isMobile ? 'mb-1' : 'mb-3'} deparment-icon-hover`}
                          src={`assets/img/icons/${service.iconHover}`}
                          alt="..."
                        />
                        <p className={`fs-1 fs-xxl-2 text-center ${selectedService.name === service.name ? 'link-secondary' : ''}`}>
                          {service.name}
                        </p>
                      </a>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
      <section className={`pt-1 ${isMobile ? 'pb-0' : 'pb-8'}`}>
        <div
          className="bg-holder bg-size"
          style={{
            backgroundImage: "url(assets/img/gallery/service-bg.png)",
            backgroundPosition: "top center",
            backgroundSize: "contain",
          }}
        ></div>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-2 mb-5 mb-lg-0"></div>
            <div className={`col-md-4 ${isMobile ? '' : 'mb-5'} mb-lg-0 order-${isMobile ? 1 : selectedService.id % 2}`}>
              <img
                className="fit-cover w-100"
                src={`assets/img/gallery/${selectedService.afterImage}`}
                alt="..."
              />
            </div>
            <div className={`col-md-4 text-center ${isMobile ? 'text-md-start order-0' : (selectedService.id + 1) % 2 ? 'text-md-end order-1' : 'text-md-start order-0'}`}>
              <h2 className="fw-bold mb-4">
                {selectedService.imageTitle}
                <br className="d-none d-sm-block" />
              </h2>
              <p dangerouslySetInnerHTML={{ __html: selectedService.description }}></p>
            </div>
            <div className="col-md-2 mb-5 mb-lg-0 order-2"></div>
          </div>
        </div>
      </section>
    </>
  );
};
