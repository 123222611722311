import { DentalServicesData } from "../data"

export const Footer = () => {
    return <>
        <section className="py-0 bg-secondary">
            <div className="bg-holder opacity-25" style={
                {
                    backgroundImage: 'url(assets/img/gallery/dot-bg.png)',
                    backgroundPosition: 'top left',
                    marginTop: '-3.125rem',
                    backgroundSize: 'auto'
                }
            }>
            </div>

            <div className="container">
                <div className="row py-8">
                    <div className="col-12 col-sm-12 col-lg-6 mb-4 order-0 order-sm-0">
                        <a className="text-decoration-none">
                            <h2 className="fw-bold mb-0 text-white font-sans-serif">AGGARWAL DENTAL CARE</h2>
                        </a>
                        <p className="text-light my-4">The world's most trusted <br />dental care service provider.</p>
                    </div>
                    <div className="col-6 col-sm-6 col-lg-2 mb-3 order-2 order-sm-1">
                        <h5 className="lh-lg fw-bold mb-4 text-light font-sans-serif">Dental Services</h5>
                        <ul className="list-unstyled mb-md-4 mb-lg-0">
                            {DentalServicesData.map(
                                (service, idx) => <li className="lh-lg" key={`foote-service-${idx}`}><a className="footer-link" href="#services">{service.name}</a></li>
                            )}
                        </ul>
                    </div>
                    <div className="col-6 col-sm-6 col-lg-2 mb-3 order-3 order-sm-2">
                        <h5 className="lh-lg fw-bold text-light mb-4 font-sans-serif"> Customer Care</h5>
                        <ul className="list-unstyled mb-md-4 mb-lg-0">
                            <li className="lh-lg"><a className="footer-link" href="#about">About Us</a></li>
                            <li className="lh-lg"><a className="footer-link" href="#contactUs">Contact US</a></li>
                        </ul>
                    </div>
                </div>
            </div>


            <section className="py-0 bg-primary">
                <div className="container">
                    <div className="row justify-content-md-between justify-content-evenly py-4">
                        <div className="col-12 col-sm-8 col-md-6 col-lg-auto text-center text-md-start">
                            <p className="fs--1 my-2 fw-bold text-200">All rights Reserved &copy;Aggarwal Dental Care, 2023</p>
                        </div>
                        <div className="col-12 col-sm-8 col-md-6">
                            <p className="fs--1 my-2 text-center text-md-end text-200"> Made with&nbsp;
                                <svg className="bi bi-suit-heart-fill" xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="#F95C19" viewBox="0 0 16 16">
                                    <path d="M4 1c2.21 0 4 1.755 4 3.92C8 2.755 9.79 1 12 1s4 1.755 4 3.92c0 3.263-3.234 4.414-7.608 9.608a.513.513 0 0 1-.784 0C3.234 9.334 0 8.183 0 4.92 0 2.755 1.79 1 4 1z"></path>
                                </svg>&nbsp;by&nbsp;
                                <a className="fw-bold text-info">RG TECH</a>
                            </p>
                        </div>
                    </div>
                </div>

            </section>
        </section>
    </>
}